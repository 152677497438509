<template>
  <div>
    <VTitle :title="$t('app.edit_role')" class="section-title" />

    <div class="grid responsive-cols gap-8 mt-10">
      <div class="flex flex-col">
        <Form
          :is-loading="isLoading"
          :data="data"
          @click:cancel="onClickCancel"
          @click:save="onClickSave"
        />

        <Logs :data="data" class="mt-8" @click="onClickLogs" />
      </div>

      <div>
        <ResourceUsers
          :id="id"
          :disabled="isDisabled"
          :endpoint="endpoint"
          :title="$t('app.role_users')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";
// Composables
import useUpdate from "@/composables/useUpdate";
import useRole from "@/composables/useRole";
// Components
import VTitle from "@/components/VTitle.vue";
import Logs from "@/components/Logs.vue";
import Form from "./Form.vue";
import ResourceUsers from "@/components/templates/ResourceUsers";
// Constants
import systemRoles from "@/constants/systemRoles";

export default {
  components: {
    VTitle,
    Form,
    ResourceUsers,
    Logs
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup(props) {
    // MISC
    const { t } = useI18n();
    const store = useStore();

    // COMPUTED
    const documentTitle = computed(() => {
      const documentTitle = `${t("app.roles")} - ${t("app.administration")}`;

      const name = data?.value?.name;
      if (name) {
        return `${name} - ${documentTitle}`;
      }

      return documentTitle;
    });
    const isSysAdmin = computed(() => store.getters["user/isSysAdmin"]);
    // eslint-disable-next-line
    const isDisabled = computed(
      () => !isSysAdmin?.value && data.value?.code === systemRoles.SYSTEM_ADMIN
    );

    // CUSTOM COMPOSABLES
    const { logType, endpoint, route } = useRole();
    const {
      data,
      isLoading,
      getData,
      onClickSave,
      onClickCancel,
      onClickLogs,
      onClickCreate
    } = useUpdate({
      logType,
      endpoint,
      route,
      id: props.id,
      relations: []
    });

    return {
      documentTitle,
      isDisabled,
      // useRole
      endpoint,
      route,
      logType,
      // useUpdate
      data,
      isLoading,
      getData,
      onClickSave,
      onClickCancel,
      onClickLogs,
      onClickCreate
    };
  }
};
</script>
